<template>
  <div>
    <section class="dashboardFilterHeader">
      <header-filters
        :filter-names="['calendar_type']"
        @onChange="onFiltersChange"
      />
      <div class="dateFilterCard">
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          offset-x
          left
          :nudge-right="126"
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formattedDate"
              v-bind="attrs"
              label=""
              readonly
              append-icon="mdi-calendar"
              hide-details
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="date"
            @input="menu1 = false"
          />
        </v-menu>
      </div>
    </section>
    <v-container
      fluid
      class="chargingDashboardContent"
    >
      <v-row>
        <v-col cols="8">
          <h2 class="chargingTableHeading">
            Charging Sessions
          </h2>
          <div class="customDataTable">
            <v-data-table
              :headers="headers"
              :items="sessions"
              class="elevation-1"
              dense
              hide-default-footer
              @click:row="openModal"
            >
              <template v-slot:item.status="{ item }">
                <div class="status-cell">
                  <span>{{ item.status }}</span>
                  <v-progress-linear
                    :value="getChargeValue(item.status)"
                    :color="getStatusColor(item.status)"
                    height="8"
                    rounded
                    class="mt-1"
                  />
                </div>
              </template>
              <template v-slot:i*tem.location="{ item }">
                <div>
                  <div>{{ item.location }}</div>
                  <small class="text--secondary">{{ item.address }}</small>
                </div>
              </template>
            </v-data-table>
          </div>
        </v-col>
        <v-col cols="4">
          <v-row dense>
            <!-- Card 1 -->
            <v-col
              cols="12"
              md="12"
            >
              <v-card class="dashboard-card">
                <div class="cardTopBar">
                  <span
                    class="cardTitle linkTextWrap"
                    @click="goToDetail"
                  >Faults & Connectivity Issues</span>
                  <div class="linkIconWrap">
                    <div @click="goToDetail">
                      <v-icon
                        class="mr-2"
                        small
                      >
                        mdi-eye
                      </v-icon>
                    </div>
                  </div>
                </div>
                <v-card-text>
                  <p class="card-value">
                    7
                  </p>
                </v-card-text>
              </v-card>
            </v-col>

            <!-- Card 2 -->
            <v-col
              cols="12"
              md="12"
            >
              <v-card class="dashboard-card">
                <div class="cardTopBar">
                  <span class="cardTitle">Charger Service Pending</span>
                </div>
                <v-card-text>
                  <p class="card-value">
                    1
                  </p>
                </v-card-text>
              </v-card>
            </v-col>

            <!-- Card 3 -->
            <v-col
              cols="12"
              md="12"
            >
              <v-card class="dashboard-card">
                <div class="cardTopBar">
                  <span class="cardTitle">Repairs Cost</span>
                </div>
                <v-card-text>
                  <p class="card-value">
                    $1,800
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- Modal -->
      <v-dialog
        v-model="showModal"
        max-width="650px"
      >
        <v-card>
          <v-card-title><span class="text-h3">Session Details</span></v-card-title>
          <v-card-text>
            <v-data-table
              :headers="modalHeaders"
              :items="modalData"
              hide-default-footer
              dense
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="showModal = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container> 
  </div>
</template>

<script>
import { debounce } from "lodash";
import { mapGetters } from "vuex";
export default {
  components: {
    HeaderFilters: () => import("@/layouts/section/MainFilters.vue"),
    //Icons: () => import("@/components/base/icons.vue"),

  },

  data() {
    return {
      menu: false,
      date: new Date().toISOString().substr(0, 10), // Initialize with today's date
      menu1: false,
      menu2: false,
      headers: [
        { text: "Charger ID", value: "id", width: "20%" },
        { text: "Charger Location", value: "location", width: "40%" },
        { text: "Status", value: "status", width: "20%" },
        { text: "Action", value: "action", width: "20%" },
      ],
      sessions: [
        { id: 11, location: "Icon Parking, Patriot", address: "575 Greenwich Street, New York", status: "60% Charged", action: "In progress" },
        { id: 89, location: "Gansevoort Street", address: "9 Gansevoort Street, NYC", status: "100% Charged", action: "Complete" },
        { id: 67, location: "MPG Parking", address: "475 West 41st St, NYC", status: "40% Charged", action: "In progress" },
        { id: 55, location: "277 Park Avenue", address: "277 Park Avenue, NYC", status: "20% Charged", action: "In progress" },
        { id: 101, location: "Broadway Parking", address: "1411 Broadway, NYC", status: "80% Charged", action: "In progress" },
        { id: 72, location: "West End Parking", address: "234 West End Ave, NYC", status: "50% Charged", action: "In progress" },
        { id: 23, location: "Hudson Parking", address: "512 Hudson St, NYC", status: "30% Charged", action: "In progress" },
        { id: 45, location: "Battery Parking", address: "70 Battery Pl, NYC", status: "90% Charged", action: "Complete" },
        { id: 34, location: "Yorkville Parking", address: "1675 York Ave, NYC", status: "75% Charged", action: "In progress" },
        { id: 58, location: "Chelsea Parking", address: "222 W 23rd St, NYC", status: "10% Charged", action: "In progress" },
      ],
      stats: {
        faults: 7,
        pending: 1,
        cost: "$1,800",
      },
      selectedSession: null, // Holds data of the selected row
      showModal: false,      // Controls modal visibility
      options: {
        type: "week",
        mode: "stack",
      },
      
      modalHeaders: [
        { text: "Car Type", value: "carType" },
        { text: "Start Time", value: "startTime" },
        { text: "End Time", value: "endTime" },
        { text: "Energy Dispensed", value: "energyDispensed" },
        { text: "Cost", value: "cost" },
      ],
      modalData: [
        { carType: "Audi e-tron", startTime: "13:50", endTime: "14:20", energyDispensed: "15 kWh", cost: "$12" },
        { carType: "Audi e-tron", startTime: "14:30", endTime: "14:40", energyDispensed: "12 kWh", cost: "$10" },
        { carType: "Audi e-tron", startTime: "14:50", endTime: "15:20", energyDispensed: "13 kWh", cost: "$18" },
        { carType: "Audi e-tron", startTime: "15:50", endTime: "16:20", energyDispensed: "11 kWh", cost: "$22" },
      ], // Data for modal table
      // types: ["month", "week", "day", "4day"],
      types: ["week", "day"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      // weekdays: [
      //   { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      //   { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      //   { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      //   { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      // ],
      value: "",
      events: [],
      //   colors: ["blue", "indigo", "deep-purple", "cyan", "green", "orange"],

      // show event
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
    };
    
    
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "csmsBookings/getLoading",
      list: "csmsBookings/getList",
    }),
    formattedDate() {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(this.date).toLocaleDateString('en-GB', options);
    },
  },
  //   watch: {
  //     "options.calendar_type": {
  //       handler: function () {
  //       },
  //     },
  //   },
  beforeDestroy() {
    const params = {
      name: "charging_dashboard",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    onFiltersChange(v) {
      this.options = v;
      this.fetchData();
    },
    fetchData: debounce(async function (val) {
      await this.$store.dispatch("csmsBookings/list", this.options);
      await this.getEvents(val);
      ("");
    }, 100),
    getStatusColor(status) {
      if (status.includes("100%")) return "#23BDAA";
      if (status.includes("90%") || status.includes("80%")) return "#5D87FF";
      if (status.includes("60%")) return "#CF9494";
      if (status.includes("50%") || status.includes("40%")) return "#FFAE20";
      if (status.includes("30%") || status.includes("20%")) return "#FA896B";
      if (status.includes("10%")) return "#9C94C6";
      return "#8A8D93";
    },
    getChargeValue(status) {
      // Extract the numerical percentage value from the status text
      return parseInt(status);
    },
    openModal(item) {
      this.selectedSession = item; // Set the selected session data
      this.showModal = true;       // Show the modal
    },
    goToDetail() {
    this.$router.push('/operations-troubleshooting/detail');
    }
  },
  
};
</script>
<style lang="sass" scoped>
$poppinReg: 'Poppins Regular', sans-serif
$poppinMed: 'Poppins Medium', sans-serif
$poppinSemi: 'Poppins SemiBold', sans-serif
$poppinBold: 'Poppins Bold', sans-serif

$primaryColor: #23BDAA

.chargingDashboardContent
  padding-left:0
  padding-right:0

.chargingDashboardContent .row--dense > [class*=col-]
  padding: 12px !important

.dashboard-card
  position: relative
  min-height: 175px
  margin: 0
  border-radius: 7px

  &::before
    content:''
    box-sizing: border-box
    position: absolute
    width: 100%
    height: 100%
    left: 0
    top: 9
    z-index: 1
    background: #FFFFFF
    border: 1px solid #F3F3F3
    box-shadow: 0px 9px 4px rgba(0, 0, 0, 0.01), 0px 4px 4px rgba(0, 0, 0, 0.02), 0px 17.9868px 35.9736px rgba(190, 185, 185, 0.01)
    border-radius: 7px

  &::after
    content:''
    position: absolute
    width: 100%
    height: 100%
    left: 0
    top: 3px
    background: #F9F9F9
    box-shadow: 0px 9px 4px rgba(0, 0, 0, 0.01), 0px 4px 4px rgba(0, 0, 0, 0.02), 0px 17.9868px 35.9736px rgba(190, 185, 185, 0.01)
    border-radius: 7px

  .cardTopBar
    width: 100%
    border-bottom: 1px solid #f3f3f3
    padding: 1.3rem 1.6rem 0.7rem
    margin-bottom: 2rem
    z-index: 2
    position: relative
    display: flex
    align-items: center
    flex-wrap: wrap
    min-height: 62px

  .cardTitle
    font-size: 1.1rem
    color: #000
    font-family: $poppinSemi

  .card-value 
    font-size: 2.8rem
    font-family: $poppinSemi
    z-index: 2
    position: relative

    &.hightlightCol
      color: #23BDAA

    .smallValueText
      font-size: 0.875rem
      font-family: &poppinReg

  .v-card__text
    display: flex
    flex-wrap: wrap
    padding: 1.3rem 1.6rem 0.7rem
    align-items: center

  .card-details 
    z-index: 2
    position: relative
    display: flex
    flex-direction: column
    margin-left: auto

    .cardIcon
      &.increase
        :deep span
          svg
            width: 1.45rem
            height: 1.45rem
            path
              fill: #10b981

      &.decrease 
        :deep span
          svg
            width: 1.45rem
            height: 1.45rem
            path
              fill: #ef4444

    span.percentText
      display: flex
      margin-top: 0.3rem
      font-size: 0.75rem
      font-family: $poppinMed

  
  .MapLinkCard
    display: flex
    margin-left: auto
    color: #C1C7C6
    text-decoration: none
    font-family: $poppinReg
    font-size: 0.875rem
  .cardListWrap
    display: flex
    position: relative
    padding-top: 0
    padding-bottom: 0
    z-index: 2
    ul
      display: flex
      flex-wrap: wrap
      padding: 0
      margin-bottom: 1rem
      li
        font-size: 0.9375rem
        color: #000
        display: flex
        flex-wrap: wrap
        width: 50%
        margin-bottom: 0.4rem
    &.fullWidth
      ul
        li
          width: 100%

.dateFilterCard
  display: flex
  margin-left: auto
  width:35%
  .v-input
    padding-top:0 !important
    margin-bottom: 0 !important
    margin-top:0 !important
    :deep .v-input__control
      .v-input__slot
        background: #F6F6F6
        border-radius: 6px
        padding: 0 0.8rem
        &:before
          display: none
        &:after
          display: none
        .v-text-field__slot
          input
            font-size: 0.875rem
            color: #22272E
        .v-input__append-inner
          .v-input__icon
            color: $primaryColor
            justify-content: flex-end
            i
              color: $primaryColor
              font-size: 1rem

.dashboardFilterHeader
  display: flex
  align-items: center
  padding: 6px 12px 0
  .headerBarWrap
    align-items: center
    padding: 0px 0

  :deep .filterBtn
    display: none !important

  .dateFilterCard
    width: 10.5%
    .v-input
      :deep .v-input__control
        .v-input__slot
          background: #f6f9fc !important
          border-radius: 6px
          padding: 0 0.8rem
          border: 1px solid #ddd

.drawerOpen .dashboardFilterHeader .dateFilterCard
  width: 12%

.drawerOpen .dashboard-card .cardTitle
  font-size: 1rem

.drawerOpen .chargingDashboardContent .row--dense > [class*=col-]
    padding: 12px 6px !important

.drawerOpen .dashboard-card .cardTopBar
  padding: 1rem 1rem 0.7rem

.drawerOpen .dashboard-card .dateFilterCard
  width: 48%

.customDataTable
  padding: 12px
  .smallTableText
    color: #8E8E8E
    font-weight: 300
    font-size: 0.675rem
  :deep .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > th
    height: 3rem
  :deep .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td
    height: 3rem
  :deep .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr:hover
    cursor: pointer  


.status-cell 
  display: flex
  flex-direction: column

.status-cell span 
  color: #22272E
  font-family: $poppinReg
  font-size: 0.9375rem
    
.stats-card 
  background-color: #ffffff
  border: 1px solid #f3f3f3
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1)


.linkIconWrap
  margin-left: auto
  display: flex
  color: #000
  cursor: pointer
  i
    color: #000

.chargingTableHeading
  font-family: $poppinMed
  font-size: 1.4rem
  padding: 0 12px
  font-weight: normal


.linkTextWrap
  display: flex
  color: #000
  cursor: pointer
  text-decoration: underline
  &:hover
    text-decoration: none


.chargingDashboardContent
  margin-top: 1.5rem


</style>
